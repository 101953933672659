import styles from './FinalPage.module.css';
import 'moment/locale/ru';
import 'moment/locale/pl';
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import URLS from '../../../../../constants/urls';
import QuestionsView from '../../../BookDialog/BookFinalPage/QuestionsView';
import TranslationTypes from '../../../../../constants/translationTypes';
import { useSelector } from 'react-redux';
import { masterDisplayName } from '../../../../../utils/formatUtils';
import { trackBookingPage, trackConversion } from '../../../../../ga';

const POLAND_TZ = "Europe/Warsaw"

const FinalPage = ({ targetUser, records, masters }) => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state?.lang).title.toLowerCase();

  const selectedTime = records && records.length > 0 ? records[0].time : null;
  const masterId = records && records.length > 0 ? records[0].masterId : 0;
  const master = masters.find(item => item.id === masterId)
  const masterName = masterDisplayName(master, lang);

  const handleInviteClick = () => {
    navigator.clipboard.writeText(URLS.invitePage(targetUser.inviteCode))
  }

  useEffect(() => {
    trackConversion();
    trackBookingPage("FinalPage");
  }, []);

  return (
    <div className={styles.box}>
      <Typography>{ t(records.length > 0 ? 'bookDialogFinalYouHaveRecordNext' : 'bookDialogFinalYouHaveRecord') }:</Typography>
      <div className={styles.time}>
        <Typography variant='h4'>{ selectedTime ? dayjs(selectedTime).tz(POLAND_TZ).format('HH:mm') : ''}</Typography>
        <Typography variant='h6'>{ dayjs(selectedTime).tz(POLAND_TZ).format('DD.MM.YYYY') }</Typography>
      </div>
      {
        master && <div className={styles.master}>
          <Typography variant='h6'>{ t('bookDialogFinalMaster') }: { masterName }</Typography>
        </div>
      }
      <QuestionsView profile={targetUser}/>
      {
        targetUser && targetUser.inviteCode && <Typography variant='a' onClick={handleInviteClick} sx={{ marginTop: 2, textDecoration: 'underline', cursor: 'pointer' }}>{ URLS.invitePage(targetUser.inviteCode)}</Typography>
      }
    </div>
  );
};

export default FinalPage;