import * as React from 'react';
import { Avatar, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from "./EditMasterServiceItemDialog.module.css"
import TranslationTypes from '../../../constants/translationTypes';
import LANGS from '../../../constants/langs';
import REQUESTS from '../../../net/requests';
import QUERY_KEYS from '../../../net/query-keys';
import { logAndReset } from '../../../utils/requestUtils';

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const EditMasterServiceItemDialog = ({ open, onClose, service, title, subtitle }) => {
  const { t } = useTranslation();
  const [minPrice, setMinPrice] = React.useState(service.minPrice ?? 0);
  const [maxPrice, setMaxPrice] = React.useState(service.maxPrice ?? 0);
  const [timeRequirements, setTimeRequirements] = React.useState(service.timeRequirements ?? 0);
  const lang = useSelector((state) => state?.lang).title.toLowerCase();
  const [errorMassage, setErrorMassage] = React.useState(null);
  
  const editMasterMutation = useMutation(REQUESTS.editMasterService(service.id));
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
  }

  const handleSave = () => {
    setErrorMassage(null)
    const data = {
      id: service.id,
      minPrice: Number(minPrice),
      maxPrice: Number(maxPrice),
      timeRequirements: Number(timeRequirements)
    }
    editMasterMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS.getService(service.serviceId));
        queryClient.invalidateQueries(QUERY_KEYS.getMasterServices(service.masterId));
        
        
        setTimeout(() => {
          onClose();
        }, 1000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error.response?.data?.error?.message))
        setErrorMassage(error.response?.data?.error?.message)
        logAndReset(editMasterMutation);
      }
    });
  }
  const findText = (titles) => titles.find(translation => translation.lang === lang)?.text;

  return (
    <BlurryDialog onClose={handleClose} open={open}  maxWidth="sm">
      <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
        { t('edit') }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0}}>
        <div>
          <Typography sx={{mt: 1, mb: 2}}>{ `${title} - ${subtitle}`}</Typography>
          <div className={styles.langBox}>
            <TextField sx={{margin: 1}} label="min price" value={minPrice} onChange={event => setMinPrice(event.target.value)}></TextField>
            <TextField sx={{margin: 1}} label="max price" value={maxPrice} onChange={event => setMaxPrice(event.target.value)}></TextField>
            <TextField sx={{margin: 1}} label="time (min)" value={timeRequirements} onChange={event => setTimeRequirements(event.target.value)}></TextField>
          </div>

          {
            errorMassage && <Typography>{ errorMassage }</Typography>
          }
          <Button variant='contained' sx={{mt: 2}} fullWidth onClick={handleSave}>{ t('save') }</Button>
        </div>
      </DialogContent>
    </BlurryDialog>
  );
};

export default EditMasterServiceItemDialog;