
import dayjs from 'dayjs';
import styles from './TimeWidget.module.css'
import { Typography } from '@mui/material';
import { POLAND_TZ } from '../../../../constants/times';

const TimeWidget = ({ record }) => {
  
  
  return <div className={styles.timeBox}>
    <Typography>{dayjs(record.startTime).tz(POLAND_TZ).format("DD.MM.YYYY")}</Typography>
    <Typography>{dayjs(record.startTime).tz(POLAND_TZ).format("HH:mm")} - {dayjs(record.endTime).tz(POLAND_TZ).format("HH:mm")}</Typography>
  </div>;
}

export default TimeWidget;