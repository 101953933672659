import { Avatar, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import styles from "./Record.module.css";
import { userDisplayName } from "../../../../../utils/formatUtils";
import ServiceWidget from "../../../../../components/widgets/profile/ServiceWidget";
import { useSelector } from "react-redux";
import TranslationTypes from "../../../../../constants/translationTypes";
import URLS from "../../../../../constants/urls";
import { Link } from "react-router-dom";
import { useState } from "react";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import RecordNoteDialog from "../../../../../components/dialogs/ScheduleRecordsItem/RecordNote/RecordNoteDialog";
import RecordNote from "../../../../../components/dialogs/ScheduleRecordsItem/RecordNote";
import { POLAND_TZ } from "../../../../../constants/times";


const Record = ({ record, client, service, id, notes, scheduleId, cacheKey }) => {
  const lang = useSelector(state => state.lang).title.toLowerCase();
  const [showNoteDialog, setShowNoteDialog] = useState(false)
  
  const serviceTitle = service.titles.find(v => v.lang === lang)?.text ?? ""
  const serviceSubtitle = service.subtitles.find(v => v.lang === lang)?.text ?? ""

  return <div className={styles.box}>
    <div className={styles.boxInfo}>
      <Typography>{ dayjs(record.startTime).tz(POLAND_TZ).format("HH:mm") } - { dayjs(record.endTime).tz(POLAND_TZ).format("HH:mm") }</Typography>
      <div className={styles.user}> 
        <Avatar alt={client?.displayName} src={ client?.avatar ? client.avatar : "/img/no_photo.png" } sx={{ width: 36, height: 36 }} />
        <Link to={URLS.profileOf(client.id)}>{ userDisplayName(client, true) }</Link>
      </div>

      
      <div className={styles.service}> 
        <Avatar alt={client?.displayName} src={ service?.imageUrl ? service?.imageUrl : "/img/no_photo.png" } sx={{ width: 36, height: 36 }} />
        <div>
          <Typography align="start">{ serviceTitle }</Typography>
          <Typography align="start">{ serviceSubtitle }</Typography>
        </div>
      </div>
      
      {
        notes.length === 0 && <div className={styles.options}>
          <IconButton aria-label="select" onClick={ () => setShowNoteDialog(true) }>
            <NoteAddIcon />
          </IconButton>
        </div>
      }
      {
        showNoteDialog && <RecordNoteDialog open={showNoteDialog} onClose={() => setShowNoteDialog(false)} recordId={record.id} scheduleId={scheduleId} cacheKey={cacheKey} />
      }
    </div>
    { 
      notes.length > 0 && <div className={styles.recordNotes}>
        { notes.map(note => <RecordNote key={note.id} note={note} recordId={record.id} scheduleId={scheduleId} cacheKey={cacheKey} />) }
      </div>
    }
  </div>
}

export default Record;