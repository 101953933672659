import React, { useCallback, useRef, useState } from "react";
import "./SchedulePanel.scss";
import { Avatar, Button, CircularProgress, Paper, TextField, styled } from '@mui/material';
import { useSelector } from "react-redux";
import Text from "../Text";
import ROLES from "../../../constants/roles";
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import { useQuery } from "react-query";
import ServerError from "../ServerError/ServerError";
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/en-GB'
import pl from 'date-fns/locale/pl'
import ScheduleInfoDialog from "../../dialogs/ScheduleInfoDialog";
import AddScheduleDialog from "../../dialogs/AddScheduleDialog";
import { useTranslation } from "react-i18next";
import LANGS from "../../../constants/langs";
import { masterDisplayName } from "../../../utils/formatUtils";
import { POLAND_TZ } from "../../../constants/times";
import { toZonedTime } from "date-fns-tz";

const locales = {
  [LANGS.EN.title.toLowerCase()]: en,
  [LANGS.PL.title.toLowerCase()]: pl,
  [LANGS.RU.title.toLowerCase()]: ru
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const ProfilePaper = styled(Paper)(({ theme }) => ({
  maxWidth: 1100,
  width: "100%",
  paddingLeft: 16,
  paddingRight: 16,
  padding: theme.spacing(2),
  marginTop: 16,
  ...theme.typography.body2,
  textAlign: 'center',
}));

const SchedulePanel = ({ profile }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state?.user);
  const lang = useSelector((state) => state.lang).title.toLowerCase();
  const eventsRef = useRef()
  const [scheduleInfoDialog, setScheduleInfoDialog] = useState({ open: false, event: null })
  const [addScheduleDialog, setAddScheduleDialog] = useState({ open: false, event: null })

  const handleSelectEvent = useCallback(
    (event) => setScheduleInfoDialog({ open: true, event }),
    []
  )
  const schedulesRequest = useQuery(QUERY_KEYS.ALL_MASTERS_SCHEDULES, REQUESTS.getAllSchedules)


  const handleDialogClosed = () => {
    setScheduleInfoDialog({ open: false, event: null });
  }

  const handleScheduleDialogClosed = () => {
    setAddScheduleDialog({ open: false, event: null });
  }
  
  if (schedulesRequest.isLoading) {
    return <CircularProgress />
  }
  if (schedulesRequest.isError) {
    return <ServerError/>
  }
  const masters = schedulesRequest.data.masters
  const schedules = schedulesRequest.data.schedule
  const mastersMap = {}
  masters.forEach(master => {
    mastersMap[master.id] = master
  });

  let roleValue
  switch (user.role) {
    case ROLES.MODERATOR:
      roleValue = "Moderator"
      break;
    case ROLES.ADMIN: 
      roleValue = "Admin"
      break;
    default:
      roleValue = "Client"
      break;
  }

  let infoItems = [
    { key: "Name", value: user.displayName},
    { key: "Email", value: user.email},
  ]
  if (user.role == ROLES.ADMIN) {
    infoItems = [...infoItems, { key: "Role", value: roleValue} ]
  }

  const events = schedules.map(item => ({
    id: item.id,
    item: item,
    master: mastersMap[item.masterId],
    title: mastersMap[item.masterId] ? masterDisplayName(mastersMap[item.masterId], lang) : '-',
    start: toZonedTime(item.startTime, POLAND_TZ),
    end: toZonedTime(item.endTime, POLAND_TZ),
  }))
  eventsRef.current = events;

  const event = events.find(item => scheduleInfoDialog.event && item.id === scheduleInfoDialog.event.id) ?? scheduleInfoDialog.event

  return (
    <>
      <div id="schedule-panel">
        <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
          
          <div id="schedule-panel-clean">
            <Calendar
              localizer={localizer}
              events={events}
              onSelectEvent={handleSelectEvent}
              startAccessor="start"
              endAccessor="end"
              culture={lang}
              style={{ height: 500 }}
            />

          </div>
          { user.role === ROLES.ADMIN && <Button variant="outlined" onClick={() => { setAddScheduleDialog({ open: true, event: null })}} sx={{marginTop: 2}}>{ t('addWorkingTime') }</Button> }
          
        </ProfilePaper>
        { addScheduleDialog.open && <AddScheduleDialog open={addScheduleDialog.open} onClose={handleScheduleDialogClosed} /> }

        { scheduleInfoDialog.open && <ScheduleInfoDialog event={event}  open={scheduleInfoDialog.open} onClose={handleDialogClosed} /> }
      </div>
    </>
  );
};

export default SchedulePanel;