import * as React from 'react';
import { Avatar, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import REQUESTS from '../../../net/requests';
import QUERY_KEYS from '../../../net/query-keys';
import styles from "./EditProfileDialog.module.css"
import ProfileNotifications from './ProfileNotifications';
import ServerError from '../../widgets/ServerError/ServerError';
import { useSelector } from 'react-redux';
import ProfileMasterNotifications from './ProfileMasterNotifications';
import ROLES from '../../../constants/roles';
import { validatePhone } from '../../../utils/validateUtils';
import { useTranslation } from 'react-i18next';

dayjs.extend(isToday);

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const EditProfileDialog = ({ open, onClose, profile }) => {
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.user);
  const master = useSelector(state => state.master);
  const [fakeName, setFakeName] = React.useState(profile.fakeName ?? '');
  const [firstName, setFirstName] = React.useState(profile.firstName ?? '');
  const [lastName, setLastName] = React.useState(profile.lastName ?? '');
  const [email, setEmail] = React.useState(profile.email ?? '');
  const [phone, setPhone] = React.useState(profile.phone ?? '');
  const [wrongPhone, setWrongPhone] = React.useState(false)
  const [userSettings, setUserSettings] = React.useState();
  const [masterSettings, setMasterSettings] = React.useState();
  const [file, setFile] = React.useState()

  const editUserMutation = useMutation(REQUESTS.editUser);
  const updateUserSettingsMutation = useMutation(REQUESTS.updateUserSettings(profile.id));
  const updateMasterSettingsMutation = useMutation(REQUESTS.updateMasterSettings());
  const queryClient = useQueryClient();
  const settingsRequest = useQuery(QUERY_KEYS.getUserSettings(profile.id), REQUESTS.getUserSettings(profile.id));
  const masterSettingsRequest = useQuery(QUERY_KEYS.getMasterSettings(master?.id ?? 0), REQUESTS.getMasterSettings(master?.id ?? 0), { enabled: master !== null && master.userId === profile.id });

  if (settingsRequest.isLoading || masterSettingsRequest.isLoading) {
    return <CircularProgress />
  }
  if (settingsRequest.isError || masterSettingsRequest.isError) {
    return <ServerError/>
  }

  const settingsData = settingsRequest.data;
  const masterSettingsData = masterSettingsRequest.data;
  const isModerator = currentUser.role === ROLES.MODERATOR && profile.id !== currentUser.id;

  const handleClose = () => {
    onClose();
  }

  const handleSave = () => {
    const data = {
      id: profile.id,
      fakeName,
      firstName,
      lastName,
      phone: phone === "" ? undefined : phone.replaceAll(" ", ""),
      email: profile.isFake ? email : undefined,
      file
    }

    if (data.phone && !validatePhone(data.phone)) {
      setWrongPhone(true);
      return;
    }

    editUserMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS.USERS);
        queryClient.invalidateQueries(QUERY_KEYS.getUserProfile(profile.id));
        
        setTimeout(() => {
          onClose();
        }, 1000)
      },
      onError: (error, variables, context) => {
        console.log(`onError:`, JSON.stringify(error))
        logAndReset(editUserMutation);
      }
    });
    if (userSettings) {
      updateUserSettingsMutation.mutate(userSettings, {
        onSuccess: (data, error, variables, context) => {
          queryClient.invalidateQueries(QUERY_KEYS.getUserSettings(profile.id));
          
          setTimeout(() => {
            onClose();
          }, 3000)
        },
        onError: (error, variables, context) => {
          console.log(`onError:`, JSON.stringify(error))
          logAndReset(updateUserSettingsMutation);
        }
      });
    }
    if (master && masterSettings) {
      updateMasterSettingsMutation.mutate(masterSettings, {
        onSuccess: (data, error, variables, context) => {
          queryClient.invalidateQueries(QUERY_KEYS.getMasterSettings(master?.id ?? 0));
          
          setTimeout(() => {
            onClose();
          }, 3000)
        },
        onError: (error, variables, context) => {
          console.log(`onError:`, JSON.stringify(error))
          logAndReset(updateMasterSettingsMutation);
        }
      });
    }
  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
    setWrongPhone(false);
  }

  const handleFileChange = (event) => {
    if (event.target.files) {
      console.log(event.target.files[0].name);
      setFile(event.target.files[0]);
    }
  }

  return (
    <BlurryDialog onClose={handleClose} open={open}  maxWidth="sm">
      <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">
        { t('edit') }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0}}>
        <div>
          <div className={styles.avatar}>
            <Avatar alt={profile?.displayName} src={profile?.avatar} sx={{ width: 112, height: 112 }} />
            <input accept="image/*" type="file" onChange={handleFileChange} />
          </div>
          {
            profile.isFake ? <>
              <TextField sx={{margin: 1}} label="Fake Name" value={fakeName} onChange={event => setFakeName(event.target.value)}></TextField>
            </> : <>
              <TextField sx={{margin: 1}} label={ t('profileFirstName') } value={firstName} onChange={event => setFirstName(event.target.value)}></TextField>
              <TextField sx={{margin: 1}} label={ t('profileLastName') } value={lastName} onChange={event => setLastName(event.target.value)}></TextField>
            </>
          }
          {
            !isModerator && <>
              <TextField sx={{margin: 1}} label={ t('email') } value={email} onChange={event => setEmail(event.target.value)} disabled={!profile.isFake}></TextField>
              <TextField error={wrongPhone} sx={{margin: 1}} label={ t('profilePhone') } value={phone} onChange={handlePhoneChange} helperText={ wrongPhone ? "Incorrect phone" : "format: +48 XXX XXX XXX" }></TextField>
            </>
          }
          <ProfileNotifications viewOnly={currentUser.id !== profile.id} settings={settingsData} onChange={setUserSettings}/>
          { masterSettingsData && <ProfileMasterNotifications settings={masterSettingsData} onChange={setMasterSettings}/> }
          {
            editUserMutation.isLoading ? <Typography>Loading...</Typography> : 
              editUserMutation.isError ? <Typography>Error: { editUserMutation.error.response.status === 403 ? editUserMutation.error.response.data.error.message : editUserMutation.error.message}</Typography> : 
                editUserMutation.isSuccess ? <Typography>User was updated!</Typography> : 
                  <div className={styles.buttons}>
                    <Button variant="outlined" onClick={handleSave} disabled={currentUser.id !== profile.id && !profile.isFake}>{ t('save') }</Button>
                    <Button variant="outlined" onClick={handleClose} sx={{ marginLeft: 2}}>{ t('cancel') }</Button>
                  </div>
          }
        </div>
      </DialogContent>
    </BlurryDialog>
  );
};

export default EditProfileDialog;
