import styles from './SelectTimePage.module.css';
import 'moment/locale/ru';
import 'moment/locale/pl';
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import SelectDay from "./SelectDay";
import SelectTime from './SelectTime';
import { trackBookingPage } from '../../../../../ga';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc)
dayjs.extend(timezone)

const POLAND_TZ = "Europe/Warsaw"

const SelectTimePage = ({ build, selected, onClick }) => {
  const [availableTime, setAvailableTime] = useState(build());
  const [selectedDay, setSelectedDay] = useState(availableTime && availableTime.length > 0 ? dayjs(availableTime[0]).tz(POLAND_TZ) : dayjs().tz(POLAND_TZ));
  const [highlightedDays, setHighlitedDays] = useState(availableTime.map( time => dayjs(time).tz(POLAND_TZ).format("YYYY-MM-DD")));

  useEffect(() => {
    trackBookingPage("TimesPage");
  }, []);

  return (
    <>
      <div className={styles.services}>
        <SelectDay 
          highlightedDays={highlightedDays}
          value={selectedDay}
          onChange={(newValue) => {
            console.log("selected day:", newValue);
            setSelectedDay(newValue)
            onClick(null)
          }}
        />
        <SelectTime value={selected} onClick={onClick} times={availableTime.filter(item => selectedDay.format("YYYY-MM-DD") === dayjs(item).tz(POLAND_TZ).format("YYYY-MM-DD"))} />
      </div>
    </>
  );
};

export default SelectTimePage;