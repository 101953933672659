import * as React from 'react';
import styles from "./ScheduleInfoDialog.module.css";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Grow, Paper, Popper, MenuItem, ClickAwayListener, MenuList, Fade, Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText, DialogContent, CircularProgress, Button, Divider, styled, Typography } from "@mui/material";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import QUERY_KEYS from '../../../net/query-keys';
import REQUESTS from '../../../net/requests';
import { useMutation, useQueryClient } from 'react-query';
import { logAndReset } from '../../../utils/requestUtils';
import ScheduleRecords from '../ScheduleRecords';
import ROLES from '../../../constants/roles';
import ChangeScheduleDialog from '../ChangeScheduleDialog';
import { useTranslation } from 'react-i18next';
import { POLAND_TZ } from '../../../constants/times';

dayjs.extend(isToday);

const DAY = 24 * 60 * 60 * 1000;

const dates = [Date.now(), Date.now() + 1 * DAY, Date.now() + 2 * DAY, Date.now() + 3 * DAY, Date.now() + 4 * DAY, Date.now() + 5 * DAY, Date.now() + 6 * DAY]

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)"
}));

const buildTimes = (selectedDayData) => {
  if (!selectedDayData) {
    return [];
  }
  const day = selectedDayData.day;
  const items = selectedDayData.times;
  const times = [];
  items.forEach(item => {
    if (item >= day && item < day + DAY) {
      times.push(item);
    }
  });
  return times;
}


const ScheduleInfoDialog = ({ event, onClose, open }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state?.user);
  const [selectedDayData, setSelectedDayData] = React.useState()
  const [selectedTime, setSelectedTime] = React.useState()
  const [master, setMaster] = React.useState()
  const [policyError, setPolicyError] = React.useState(false)
  const [recordError, setRecordError] = React.useState()
  const [showChangeDialog, setShowChangeDialog] = React.useState(false)
  const policyRef = React.useRef()
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const removeScheduleMutation = useMutation(REQUESTS.removeSchedule)

  const handleRemoveSchedule = () => {
    const data = {
      masterId: event.item.masterId,
      scheduleId: event.item.id
    }
    removeScheduleMutation.mutate(data, {
      onSuccess: (data, error, variables, context) => {
        console.log(`schedule was removed successfully!`)
        queryClient.invalidateQueries(QUERY_KEYS.getMasterSchedules(event.item.masterId));
        queryClient.invalidateQueries(QUERY_KEYS.ALL_MASTERS_SCHEDULES);
        queryClient.invalidateQueries(QUERY_KEYS.ALL_MASTERS_SCHEDULES_FREE);
        
        setTimeout(() => {
          onClose();
        }, 3000)
      },
      onError: () => {
        logAndReset(removeScheduleMutation);
      }
    });
  }


  const handleClose = (event) => {
    onClose();
  };

  const changeScheduleTime = () => {
    setShowChangeDialog(true)
  }

  const title = event.title + ' - ' + dayjs(event.item.startTime).tz(POLAND_TZ).format("DD.MM.YYYY")

  console.log(`event: Working time: ${dayjs(event.item.startTime).tz(POLAND_TZ).format("HH:mm")} - ${dayjs(event.item.endTime).tz(POLAND_TZ).format("HH:mm")}`);
  return (
    <>
      <BlurryDialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
        <DialogTitle sx={{ m: 0, paddingRight:7 }} id="customized-dialog-title">{ title }</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
          <div className={styles.scheduleInfoDialog}>
            <div>
              <Typography>{ t('workingTime') }: {dayjs(event.item.startTime).tz(POLAND_TZ).format("HH:mm")} - {dayjs(event.item.endTime).tz(POLAND_TZ).format("HH:mm")}</Typography>
              { event.item.endTime > Date.now() && <Button variant='outlined' onClick={changeScheduleTime}>{ t('change') }</Button> }
            </div>
            <ScheduleRecords scheduleId={event.item.id}/>

            {
              removeScheduleMutation.isLoading ? ('Removing schedule...') : 
              <>
                { removeScheduleMutation.isError ? <div>An error occurred: {removeScheduleMutation.error.message}</div> : null }
                { removeScheduleMutation.isSuccess ? <div>Time was removed!</div> : null }
              </>
            }
            { user.role === ROLES.ADMIN && event.item.endTime > Date.now() && <Button variant='outlined' onClick={handleRemoveSchedule}>{ t('delete') }</Button> }
            
            {
              showChangeDialog && <ChangeScheduleDialog open={showChangeDialog} onClose={() => setShowChangeDialog(false)} title={title} startTime={event.item.startTime} endTime={event.item.endTime} masterId={event.item.masterId} scheduleId={event.item.id} />
            }
          </div>
        </DialogContent>
      </BlurryDialog>
    </>
  );
};

export default ScheduleInfoDialog;
