import { CircularProgress, Link, styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import MasterListItem from "../../components/widgets/MasterListItem";
import ServerError from "../../components/widgets/ServerError/ServerError";
import QUERY_KEYS from "../../net/query-keys";
import REQUESTS from "../../net/requests";
import styles from "./CategoriesPage.module.css"
import CategoryItem from "../../components/widgets/CategoryItem";
import { useTranslation } from "react-i18next";
import PageLayout from "../../components/layouts/PageLayout";
import OrderDialog from "../../components/dialogs/OrderDialog";

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.onBackground,
  padding: theme.spacing(1)
}));

const ACTION_KEY = "a";
const ACTION_BOOK = "book";

const CategoriesPage = () => {
  const categoriesRequest = useQuery(QUERY_KEYS.CATEGORIES, REQUESTS.getCategories)
  const { t } = useTranslation();
  const [bookDialog, setBookDialog] = useState({ open: false, service: null })
  const action = new URLSearchParams(document.location.search).get(ACTION_KEY)
  //const [actionConsumed, setActionConsumed] = useState({ open: false, service: null })

  useEffect(() => {
    console.log("action", action)
    if (action === ACTION_BOOK) {
      setBookDialog({ open: true, service: null })
    }
  }, [])

  const handleDialogClosed = () => {
    setBookDialog({ open: false, service: null });
  }

  if (categoriesRequest.isLoading) {
    return <CircularProgress />
  }
  if (categoriesRequest.isError) {
    return <ServerError/>
  }

  const categories = categoriesRequest.data.sort((a, b) => a.id - b.id)

  return (
    <PageLayout>
      <Typography className={styles.title} variant="h4" align="center" >{ t('topMenuServices') }</Typography>

      <div className={styles.list}>
        { categories.map(category => <CategoryItem key={category.id} category={category} />) }
      </div>

      { bookDialog.open && <OrderDialog onClose={handleDialogClosed} open={bookDialog.open} /> }
    </PageLayout>
  );
};

export default CategoriesPage;
