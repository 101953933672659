import styles from './SelectTimeItem.module.css';
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import { Typography } from '@mui/material';


const POLAND_TZ = "Europe/Warsaw"

const SelectTimeItem = ({ selected, onClick, time }) => {
  
  const lang = useSelector((state) => state?.lang).title.toLowerCase();

  return (
    <>
      <div onClick={onClick} className={`${styles.item} ${selected ? styles.selected : styles.unselected}`}>
        <Typography sx={{ fontSize: '0.9rem' }}>{ dayjs(time).tz(POLAND_TZ).format("HH:mm") }</Typography>
      </div>
    </>
  );
};

export default SelectTimeItem;