import styles from './SelectTime.module.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'moment/locale/ru';
import 'moment/locale/pl';
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import SelectTimeItem from './SelectTimeItem';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';


const POLAND_TZ = "Europe/Warsaw"

const SelectTime = ({ value, onClick, times }) => {
  
  const lang = useSelector((state) => state?.lang).title.toLowerCase();
  const { t } = useTranslation();

  const morning = times.filter(item => {
    const itemTime = dayjs(item).tz(POLAND_TZ)
    return itemTime.hour() > 3 && itemTime.hour() < 12
  })
  const daylight = times.filter(item => {
    const itemTime = dayjs(item).tz(POLAND_TZ)
    return itemTime.hour() >= 12 && itemTime.hour() < 18
  })
  const evening = times.filter(item => {
    const itemTime = dayjs(item).tz(POLAND_TZ)
    return itemTime.hour() >= 18 && itemTime.hour() < 22
  })
  const night = times.filter(item => {
    const itemTime = dayjs(item).tz(POLAND_TZ)
    return itemTime.hour() >= 22 && itemTime.hour() < 24 || itemTime.hour() >= 0 && itemTime.hour() <= 3
  })
  return (
    <>
      {
        morning.length > 0 && <>
          <Typography align='center'>{ t("orderDialogTimeMorning") }</Typography>
          <div className={styles.times}>
            { 
              morning.map(time => <SelectTimeItem key={time} time={time} selected={time === value} onClick={() => onClick(time)}/>)
            }
          </div>
        </>
      }
      {
        daylight.length > 0 && <>
          <Typography align='center' sx={{ paddingTop: morning.length > 0 ? 2 : 0 }}>{ t("orderDialogTimeAfternoon") }</Typography>
          <div className={styles.times}>
            { 
              daylight.map(time => <SelectTimeItem key={time} time={time} selected={time === value} onClick={() => onClick(time)}/>)
            }
          </div>
        </>
      }
      {
        evening.length > 0 && <>
          <Typography align='center' sx={{ paddingTop: (morning.length + daylight.length) > 0 ? 2 : 0 }}>{ t("orderDialogTimeEvening") }</Typography>
          <div className={styles.times}>
            { 
              evening.map(time => <SelectTimeItem key={time} time={time} selected={time === value} onClick={() => onClick(time)}/>)
            }
          </div>
        </>
      }
      {
        night.length > 0 && <>
          <Typography align='center' sx={{ paddingTop: (morning.length + daylight.length + evening.length) > 0 ? 2 : 0 }}>{ t("orderDialogTimeNight") }</Typography>
          <div className={styles.times}>
            { 
              night.map(time => <SelectTimeItem key={time} time={time} selected={time === value} onClick={() => onClick(time)}/>)
            }
          </div>
        </>
      }
      {
        (morning.length + daylight.length + evening.length + night.length) === 0 && <Typography align='center'>{ t("orderDialogTimeNoItems") }</Typography>
      }
    </>
  );
};

export default SelectTime;