import { useState, Fragment, useEffect } from 'react';
import styles from "./Slots.module.css";
import { CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography, styled } from "@mui/material";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { useQuery } from 'react-query';
import QUERY_KEYS from '../../../../net/query-keys';
import REQUESTS from '../../../../net/requests';
import ServerError from '../../../../components/widgets/ServerError/ServerError';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import SlotsList from './SlotsList';
import { useTranslation } from 'react-i18next';
import { POLAND_TZ } from '../../../../constants/times';

dayjs.extend(isToday);

const ONE_HOUR = 60 * 60 * 1000

const Slots = ({ schedules, master, allMasters, masters }) => {
  const { t } = useTranslation();
  const [startDay, setStartDay] = useState(dayjs().tz(POLAND_TZ));
  const [endDay, setEndDay] = useState(dayjs().tz(POLAND_TZ));
  const [items, setItems] = useState([]);
  const [minTime, setMinTime] = useState(ONE_HOUR);
  const scheduleIds = schedules.filter(schedule => allMasters || schedule.masterId === master.id).map(schedule => schedule.id)
  const [ids, setIds] = useState(scheduleIds);
  const cacheKey = QUERY_KEYS.recordsOfSchedulesWithIds(scheduleIds);
  const recordsRequest = useQuery(cacheKey, REQUESTS.getRecordsOfSchedulesWithIds(scheduleIds), { refetchOnMount: true });



  const buildFreeSlotsList = (schedule, records) => {
    const times = [];
    if (records.length === 0) {
      times.push({
        startTime: schedule.startTime,
        endTime: schedule.endTime
      })
    } else {
      const sortedRecords = records.sort((a, b) => a.startTime - b.startTime)
      var start = schedule.startTime;
      sortedRecords.forEach(record => {
        if (start < record.startTime) {
          times.push({
            startTime: start,
            endTime: record.startTime
          })
          start = record.endTime;
        } else {
          start = record.endTime;
        }
      });
      if (start < schedule.endTime) {
        times.push({
          startTime: start,
          endTime: schedule.endTime
        })
      }
    }
    const result = [];
    const TIME = minTime === ONE_HOUR ? ONE_HOUR : (ONE_HOUR / 2);
    times.forEach(range => {
      var current = range.startTime % TIME === 0 ? range.startTime : (Math.floor(range.startTime / TIME) * TIME + TIME);
      while (current + minTime <= range.endTime) {
        result.push(current)
        current = current + minTime;
      }
    });
    return result;
  }

  useEffect(() => {
    if (recordsRequest.isLoading || recordsRequest.isError) {
      setItems([]);
    } else {
      recordsRequest.data.map(info => {
        return { id: 1, info}
      })
      const result = new Set();
      recordsRequest.data.forEach(info => buildFreeSlotsList(schedules.find(schedule => schedule.id === info.id), info.data.records).forEach(item => result.add(item)));
      
      setItems(Array.from(result).sort((a, b) => a - b));
    }
  }, [startDay, endDay, minTime, master, recordsRequest.isLoading]);

  if (recordsRequest.isLoading) {
    return <CircularProgress />
  }
  if (recordsRequest.isError) {
    return <ServerError/>
  }

  const handleStartTimeChanged = (day) => {
    setStartDay(day);
    if (endDay.isBefore(day)) {
      setEndDay(day);
    }
  }

  const handleEndTimeChanged = (day) => { 
    setEndDay(day); 
  }

  const itemsFilter = (item) => {
    return startDay.startOf('day').isBefore(dayjs(item).tz(POLAND_TZ)) && endDay.endOf('day').isAfter(dayjs(item).tz(POLAND_TZ))
  }

  const handleChangeMinTime = (event) => {
    setMinTime(Number(event.target.value))
  }

  return (
    <div className={styles.slotsBox}>
      <div>
        <FormControl>
          <FormLabel>{ t('freeSlotsInterval') }</FormLabel>
          <RadioGroup row value={minTime} onChange={handleChangeMinTime}>
            <FormControlLabel value={ 30 * 60 * 1000 } control={<Radio />} label={`30 ${t('min')}`} />
            <FormControlLabel value={ 60 * 60 * 1000 } control={<Radio />} label={`60 ${t('min')}`} />
          </RadioGroup>
        </FormControl>
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <DatePicker label={ t('slotsStartDay') } disablePast={true} value={startDay} onChange={handleStartTimeChanged} sx={{ m: 1 }}/>
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <DatePicker label={ t('slotsEndDay') } disablePast={true} value={endDay} onChange={handleEndTimeChanged} sx={{ m: 1 }}/>
      </LocalizationProvider>
      
      {
        items.length > 0 ? <SlotsList startDay={startDay} endDay={endDay} dates={items.filter(itemsFilter)}/> : <p>{ t("slotsEmpty") }</p>
      }
    </div>
  );
};

export default Slots;
