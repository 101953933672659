const HOST = process.env.REACT_APP_ENV == "production" ? "https://facetime-cosmetology.pro:1551" : "http://localhost:3000";

const ENDPOINTS = {
  LOGIN: `${HOST}/v1/auth/login`,
  GET_MASTER: `${HOST}/v1/masters/current`,
  SERVICES: `${HOST}/v1/services/`,
  MASTERS: `${HOST}/v1/masters/`,
  PROFILE: `${HOST}/v1/auth/current`,
  TIME_FRAME: `${HOST}/v1/masters/`,
  ADD_SCHEDULE: `${HOST}/v1/masters/addSchedule`,
  RECORDS: `${HOST}/v1/records/`,
  ALL_SCHEDULES: `${HOST}/v1/masters/schedules`,
  ALL_SCHEDULES_FREE: `${HOST}/v1/masters/schedules/free`,
  CONSENTS: `${HOST}/v1/consents/`,
  CATEGORIES: `${HOST}/v1/categories/`,
  CATEGORIES_ALL: `${HOST}/v1/categories/all`,
  UPLOAD_IMAGE: `${HOST}/v1/files/upload-image`,
  USERS: `${HOST}/v1/auth/users`,
  QUESTIONS: `${HOST}/v1/questions`,
  ANSWERS: `${HOST}/v1/answers`,
  FEATURED: `${HOST}/v1/services/featured`,
  ADD_SERVICE_TO_MASTER: `${HOST}/v1/masters/addService`,
  REMOVE_MASTER_SERVICE: `${HOST}/v1/masters/removeService`,
  UPDATE_MASTER_SERVICE: `${HOST}/v1/masters/updateService`,
  CHANGE_ACTIVE_MASTER: `${HOST}/v1/masters/changeActive`,
  CREATE_FAKE_USER: `${HOST}/v1/auth/fakeUser`,
  MERGE_FAKE_USER: `${HOST}/v1/auth/linkFake`,
  SESSION: `${HOST}/v1/auth/session`,
  METRICS: `${HOST}/v1/metrics`,

  getUserProfile: (userId) => `${HOST}/v1/profiles/${userId}`,
  getSchedules: (masterId) => `${HOST}/v1/masters/${masterId}/schedules`,
  removeSchedule: (masterId, scheduleId) => `${HOST}/v1/masters/${masterId}/schedules/${scheduleId}`,
  changeSchedule: (masterId, scheduleId) => `${HOST}/v1/masters/${masterId}/schedules/${scheduleId}`,
  getMastersForService: (serviceId) => `${HOST}/v1/masters/filter?serviceId=${serviceId}`,
  getMasterService: (masterId, serviceId) => `${HOST}/v1/masters/${masterId}/services/${serviceId}`,
  getMasterServices: (masterId) => `${HOST}/v1/masters/${masterId}/services`,
  deleteMaster: (masterId) => `${HOST}/v1/masters/${masterId}`,
  findFreeSlots: (masterId, serviceId, startTime, endTime) => `${HOST}/v1/records/find?masterId=${masterId}&serviceId=${serviceId}&startTime=${startTime}&endTime=${endTime}`,
  getRecordsOfSchedule: (scheduleId) => `${HOST}/v1/records/schedules/${scheduleId}`,
  getRecordsOfSchedules: () => `${HOST}/v1/records/schedules/ids`,
  getConsentWithText: (consentId) => `${HOST}/v1/consents/${consentId}/text`,
  getConsentForType: (type) => `${HOST}/v1/consents/types/${type}`,
  getServicesForCategory: (categoryId) => `${HOST}/v1/services/filter?categoryId=${categoryId}`,
  updateCategory: (categoryId) => `${HOST}/v1/categories/${categoryId}`,
  deleteService: (serviceId) => `${HOST}/v1/services/${serviceId}`,
  addToCategory: (categoryId) => `${HOST}/v1/categories/${categoryId}/add`,
  serviceCategories: (serviceId) => `${HOST}/v1/services/${serviceId}/categories`,
  deleteFromCategory: (serviceId, categoryId) => `${HOST}/v1/services/${serviceId}/categories/${categoryId}`,
  getConsent: (consentId) => `${HOST}/v1/consents/${consentId}/text`,
  getFileUrl: (fileId) => `${HOST}/v1/files/${fileId}`,
  newQuestionItem: () => `${HOST}/v1/questions/`,
  updateQuestion: (questionId) => `${HOST}/v1/questions/${questionId}`,
  getAnswersState: (userId) => `${HOST}/v1/answers/state/${userId}`,
  getQuestionnaire: (userId) => `${HOST}/v1/answers/questionnaire/${userId}`,
  featured: (serviceId) => `${HOST}/v1/services/${serviceId}/featured`,
  getService: (serviceId) => `${HOST}/v1/services/${serviceId}`,
  putService: (serviceId) => `${HOST}/v1/services/${serviceId}`,
  getServiceRisks: (serviceId) => `${HOST}/v1/services/${serviceId}/risks`,
  removeRecord: (recordId) => `${HOST}/v1/records/${recordId}`,
  getUserRecords: (userId) => `${HOST}/v1/records/forUser/${userId}`,
  user: (userId) => `${HOST}/v1/auth/users/${userId}`,
  userSettings: (userId) => `${HOST}/v1/profiles/${userId}/settings`,
  masterSettings: (masterId) => `${HOST}/v1/masters/${masterId}/settings`,
  updateMasterSettings: () => `${HOST}/v1/masters/settings`,
  getGeneralMetrics: (startTime, endTime) => `${HOST}/v1/metrics/general?startTime=${startTime}&endTime=${endTime}`,
  getUnconfirmedRecords: (masterId) => `${HOST}/v1/records/unconfirmed?masterId=${masterId}`,
  postConfirmRecord: (recordId) => `${HOST}/v1/records/${recordId}/confirm`,
  postAcceptRecord: (recordId) => `${HOST}/v1/records/${recordId}/accept`,
  getConfirmationResult: () => `${HOST}/v1/records/emailConfirmation`,
  getServiceDetailsByCode: (code) => `${HOST}/v1/records/details?code=${code}`,
  getUserHistory: (userId) => `${HOST}/v1/records/history/${userId}`,
  getBookItems: () => `${HOST}/v1/booking/items`,
  postRecords: () => `${HOST}/v1/booking/records`,
  getMasterNote: (profileId) => `${HOST}/v1/notes/forUser/${profileId}`,
  masterNote: () => `${HOST}/v1/notes/`,
  getPopularMetrics: (startTime, endTime) => `${HOST}/v1/metrics/popular?startTime=${startTime}&endTime=${endTime}`,
  recordNote: () => `${HOST}/v1/recordNotes/`,
  feedbacks: () => `${HOST}/v1/feedbacks/`,
  newFeedbacks: () => `${HOST}/v1/feedbacks/new`,
  feedbackAnswered: (id) => `${HOST}/v1/feedbacks/${id}/markAnswered`,
  editMaster: (masterId) => `${HOST}/v1/masters/${masterId}`,
  updateSettings: () => `${HOST}/v1/settings/timeFrame`,
  getMastersMetrics: (startTime, endTime) => `${HOST}/v1/metrics/masters?startTime=${startTime}&endTime=${endTime}`,
  generateAgreementCode: (id) => `${HOST}/v1/records/codes/generate?id=${id}`,
  checkRecordCode: () => `${HOST}/v1/records/codes/check`,
  acceptRecordCode: () => `${HOST}/v1/records/codes/accept`,
  resetRecordCode: () => `${HOST}/v1/records/codes/reset`,
  moveRecord: () => `${HOST}/v1/records/move`,
  clientReviews: () => `${HOST}/v1/feedbacks/reviews`,
  editMasterService: (id) => `${HOST}/v1/masters/updateService/${id}`,
}

export default ENDPOINTS;