import React, { useRef, useState } from "react";
import styles from "./EmptyMasterSchedule.module.css"
import { CircularProgress, Paper, styled } from "@mui/material";
import ExpandMoreItem from "../../../components/widgets/ControlPanelItems/ExpandMoreItem2";
import { useQuery, useQueryClient } from "react-query";
import QUERY_KEYS from "../../../net/query-keys";
import REQUESTS from "../../../net/requests";
import MasterSelect from "./MasterSelect";
import ServerError from "../../../components/widgets/ServerError/ServerError";
import SelectDay from "./SelectDay";
import dayjs from "dayjs";
import DaySchedule from "./DaySchedule";
import { useTranslation } from "react-i18next";
import Slots from "./Slots";
import { POLAND_TZ } from "../../../constants/times";

const ProfilePaper = styled(Paper)(({ theme }) => ({
  maxWidth: 1100,
  width: "100%",
  paddingLeft: 16,
  paddingRight: 16,
  padding: theme.spacing(2),
  marginTop: 16,
  ...theme.typography.body2,
  textAlign: 'center',
}));

const ONE_DAY = 24 * 60 * 60 * 1000;
const ALL_MASTERS = { id: 0, name: "All", surname: "" };

const rangeToDaysFormatted = (range) => {
  const set = new Set();
  for (let start = range.startTime; start < range.endTime; start += ONE_DAY) {
    set.add(dayjs(start).tz(POLAND_TZ).format("YYYY-MM-DD"))
  }
  set.add(dayjs(range.endTime).tz(POLAND_TZ).format("YYYY-MM-DD"))
  return set;
}

// const rangeToDaysFormatted = (range) => {
//   return Array.from(rangeToDays(range)).map(v => v.format("YYYY-MM-DD"))
// }



const EmptyMasterSchedule = ({ profile }) => {
  const { t } = useTranslation();
  const [master, setMaster] = useState(ALL_MASTERS)
  const [day, setDay] = useState(dayjs().tz(POLAND_TZ).startOf("day"))
  ALL_MASTERS.name = t("allMasters");
  
  const schedulesRequest = useQuery(QUERY_KEYS.ALL_MASTERS_SCHEDULES_FREE, REQUESTS.getAllSchedulesFree)
  const queryClient = useQueryClient();

  if (schedulesRequest.isLoading) {
    return <CircularProgress />
  }
  if (schedulesRequest.isError) {
    return <ServerError/>
  }
  const data = schedulesRequest.data
  data.masters = data.masters.filter(master => !master.wasDeleted)

  const handleChangeDay = (selectedDay) => {
    setDay(selectedDay)
  }

  const handleChangeMaster = (master) => {
    setMaster(master);
    queryClient.invalidateQueries([QUERY_KEYS.FREE_SLOTS_DATA]);
  }

  const findSchedulesIds = (schedules, day, master) => {
    const masterSchedules = schedules.filter(item => master.id === 0 || item.masterId === master.id)
    const result = masterSchedules.filter(item => Array.from(rangeToDaysFormatted(item)).find(item => item === day.tz(POLAND_TZ).format("YYYY-MM-DD"))).map(item => item.id)
    console.log("result", result);
    return result;
  }

  const buildDays = (ranges) => {
    const set = new Set();
    ranges.forEach(range => {
      rangeToDaysFormatted(range).forEach(day => set.add(day))
    });
    console.log("set", set);
    return Array.from(set);
  }

  const masters = [ALL_MASTERS, ...data.masters]

  return (
    <div className={styles.box}>
      <ProfilePaper variant="outlined" sx={{ borderRadius: 2 }}>
        <ExpandMoreItem text={ t('freeTimeOfMaster') } sx={{ padding: 0}}>
          <div>
            <MasterSelect masters={masters} value={master} onChange={handleChangeMaster}/>
            {
              master && <SelectDay value={day} onChange={handleChangeDay} highlightedDays={buildDays(data.schedule.filter(item => master.id === 0 || item.masterId === master.id))} />
            }
            {
              day && master && <DaySchedule schedules={data.schedule} scheduleIds={findSchedulesIds(data.schedule, day, master)} masters={data.masters}/>
            }
            {
              <Slots schedules={data.schedule} day={day} master={master} allMasters={master.id === 0} masters={data.masters}/>
            }
          </div>
        </ExpandMoreItem>
      </ProfilePaper>
    </div>
  );
}

export default EmptyMasterSchedule;