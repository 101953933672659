import FinalPage from "./FinalPage";
import InitPage from "./InitPage";
import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import RestorePage from "./RestorePage";
import SelectMasterPage from "./SelectMasterPage";
import SelectServicePage from "./SelectServicePage";
import SeletTimePage from "./SelectTimePage";
import SummaryPage from "./SummaryPage";


const buildPages = (navigator, t) => ({
  init: {
    key: "init",
    hasBackNavigation: false,
    hasForwardNavigation: false,
    title: t("orderDialogInitTitle"),
    content: () => <InitPage navigator={navigator} />
  },
  selectService: {
    key: "services",
    hasBackNavigation: true,
    hasForwardNavigation: true,
    title: t("orderDialogServicesTitle"),
    nextText: t("orderDialogServicesNextText"),
    content: ({ build, categories, services, categoryServices, selected, onClick }) => <SelectServicePage build={build} categories={categories} services={services} categoryServices={categoryServices} selectedId={selected} onClick={onClick} />
  },
  selectMaster: {
    key: "masters",
    hasBackNavigation: true,
    hasForwardNavigation: true,
    title: t("orderDialogMastersTitle"),
    nextText: t("orderDialogMastersNextText"),
    content: ({ build, masters, masterServices, selected, onClick }) => <SelectMasterPage build={build} masters={masters} masterServices={masterServices} selectedId={selected} onClick={onClick}/>
  },
  selectTime: {
    key: "time",
    hasBackNavigation: true,
    hasForwardNavigation: true,
    title: `${t("orderDialogTimesTitle")} (Europe/Warsaw)`,
    nextText: t("orderDialogTimesNextText"),
    content: ({ build, selected, onClick }) => <SeletTimePage build={build} selected={selected} onClick={onClick}/>
  },
  summary: {
    key: "summary",
    hasBackNavigation: false,
    hasForwardNavigation: true,
    title: t("orderDialogSumarryTitle"),
    nextText: t("orderDialogSumarryNextText"),
    content: ({ records, services, masters, policyRef, policyError, onDelete }) => <SummaryPage records={records} services={services} masters={masters} navigator={navigator} policyRef={policyRef} policyError={policyError} onDelete={onDelete}/>
  },
  login: {
    key: "login",
    hasBackNavigation: true,
    hasForwardNavigation: false,
    title: t("orderDialogLoginTitle"),
    nextText: t("authorize"),
    content: ({ targetUser, records, masters }) => <LoginPage navigator={navigator} />
  },
  register: {
    key: "register",
    hasBackNavigation: true,
    hasForwardNavigation: false,
    title: t("orderDialogLoginTitle"),
    nextText: t("authorize"),
    content: ({ targetUser, records, masters }) => <RegisterPage navigator={navigator} />
  },
  restore: {
    key: "restore",
    hasBackNavigation: true,
    hasForwardNavigation: false,
    title: t("orderDialogLoginTitle"),
    nextText: t("authorize"),
    content: ({ targetUser, records, masters }) => <RestorePage navigator={navigator} />
  },
  final: {
    key: "final",
    hasBackNavigation: false,
    hasForwardNavigation: false,
    title: t("orderDialogFinalTitle"),
    nextText: t("orderDialogFinalNextText"),
    content: ({ targetUser, records, masters }) => <FinalPage targetUser={targetUser} records={records} masters={masters} />
  }
})

export default buildPages;